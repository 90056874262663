import MapLayersIconSolid from '@/public/icons/map-layers-icon-solid.svg'
import MapLegendIcon from '@/public/icons/map-legend-icon-outline.svg'
import type { Legend } from '@/components/mapbox/types'
import type { Map, IControl } from 'mapbox-gl'

class StyleSwitcherControl implements IControl {
	styles: { label: string, url: string }[]
	container: HTMLDivElement

	constructor (styles: { label: string, url: string }[]) {
		this.styles = styles
		this.container = document.createElement('div')
		this.container.className = 'grid grid-cols-1 mapboxgl-ctrl font-bold divide-y pointer-events-auto min-w-24 max-w-[6rem] divide-mx-gray-200 border-2 border-black/10 rounded'
	}

	onAdd (map: Map) {
		this.styles.forEach((style) => {
			const button = document.createElement('button')
			button.textContent = style.label
			button.className = 'w-full px-2 py-1 m-0 text-center shadow-sm cursor-pointer first-of-type:rounded-t last-of-type:rounded-b'
			button.style.backgroundColor = 'white'
			button.style.transition = 'background-color 0.1s ease'
			button.onmouseenter = () => {
				button.style.backgroundColor = '#E6E6E6'
			}
			button.onmouseleave = () => {
				button.style.backgroundColor = 'white'
			}
			button.onclick = () => map?.setStyle(style.url)
			this.container.appendChild(button)
		})

		return this.container
	}

	onRemove () {
		this.container.parentNode?.removeChild(this.container)
	}
}

class LayerVisibilityControl implements IControl {
	layers: { name: string, id: string, visibility: boolean, hideInVisibilityControl: boolean, siblingLayerIds: string[] }[]
	container: HTMLDivElement
	buttonContainer: HTMLDivElement
	contentContainer: HTMLDivElement
	isExpanded: boolean
	map: Map

	constructor (map: Map, layers: { name: string, id: string, visibility: boolean, hideInVisibilityControl: boolean, siblingLayerIds: string[] }[]) {
		this.map = map
		this.layers = layers.map(layer => ({
			name: layer.name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replace(' Layer', ''),
			id: layer.id,
			visibility: layer.visibility,
			hideInVisibilityControl: layer.hideInVisibilityControl,
			siblingLayerIds: layer.siblingLayerIds
		}))

		this.isExpanded = false

		// Main container for the entire control
		this.container = document.createElement('div')
		this.container.className = 'mapboxgl-ctrl font-bold pointer-events-auto max-w-[12rem]'

		// Button container for the toggle button
		this.buttonContainer = document.createElement('div')
		this.buttonContainer.className = 'flex items-center justify-end ml-auto w-[33px] h-[33px] -mr-0.5 rounded border-2 border-black/10'

		// Button to toggle visibility of the layers
		const toggleButton = document.createElement('button')
		toggleButton.className = 'w-full h-full p-1 rounded shadow-sm cursor-pointer'
		toggleButton.style.backgroundColor = 'white'
		toggleButton.style.transition = 'background-color 0.1s ease'
		toggleButton.onmouseenter = () => {
			toggleButton.style.backgroundColor = '#E6E6E6'
		}
		toggleButton.onmouseleave = () => {
			toggleButton.style.backgroundColor = 'white'
		}
		toggleButton.onclick = () => this.toggleVisibility()

		// Using the imported SVG as an image
		const img = document.createElement('img')
		img.src = MapLayersIconSolid
		img.alt = 'Layer Visibility'
		img.className = 'w-[20px] h-[20px] m-auto rounded text-mx-gray-500'

		toggleButton.appendChild(img)

		this.buttonContainer.appendChild(toggleButton)
		this.container.appendChild(this.buttonContainer)

		// Content container for the layer buttons
		this.contentContainer = document.createElement('div')
		this.contentContainer.className = 'mt-1 overflow-hidden transition-all duration-300 ease-in-out bg-white rounded shadow-sm max-h-0'

		this.layers.forEach((layer) => {
			if (layer.hideInVisibilityControl) { return }
			const button = document.createElement('button')
			button.className = 'flex items-center justify-between w-full px-2 py-1 my-0 ml-auto shadow-sm cursor-pointer first-of-type:rounded-t last-of-type:rounded-b'
			button.id = layer.id
			button.innerHTML = `<span class="mr-4">${layer.visibility ? '✅' : '❌'}</span>`
			button.innerHTML += `<span>${layer.visibility ? `${layer.name}` : `${layer.name}`}</span>`
			button.style.backgroundColor = 'white'
			button.style.transition = 'background-color 0.1s ease'
			button.onmouseenter = () => {
				button.style.backgroundColor = '#E6E6E6'
			}
			button.onmouseleave = () => {
				button.style.backgroundColor = 'white'
			}
			button.onclick = () => {
				const visibility = this.map.getLayoutProperty(layer.id, 'visibility')

				button.innerHTML = `<span class="mr-4">${visibility === 'none' ? '✅' : '❌'}</span>`
				button.innerHTML += `<span>${layer.name}</span>`

				this.map.setLayoutProperty(layer.id, 'visibility', visibility === 'visible' ? 'none' : 'visible')

				if (layer.siblingLayerIds?.length) {
					layer.siblingLayerIds.forEach((siblingLayerId) => {
						this.map.setLayoutProperty(siblingLayerId, 'visibility', visibility === 'visible' ? 'none' : 'visible')
					})
				}
			}
			this.contentContainer.appendChild(button)
		})

		this.container.appendChild(this.contentContainer)
	}

	onAdd () {
		return this.container
	}

	toggleVisibility () {
		this.isExpanded = !this.isExpanded
		this.contentContainer.style.maxHeight = this.isExpanded ? `${this.contentContainer.scrollHeight}px` : '0'
	}

	updateLayers (layers: { name: string, id: string, visibility: boolean, hideInVisibilityControl: boolean, siblingLayerIds: string[] }[]) {
		this.layers = layers
		this.layers.forEach((layer) => {
			if (layer.hideInVisibilityControl) { return }
			const button = Array.from(this.contentContainer.children)?.find((child: Element) => {
				const button = child as HTMLButtonElement
				return button.id === layer.id
			})
			const layerName = layer.name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replace(' Layer', '')

			if (button) {
				button.innerHTML = `<span class="mr-4">${layer.visibility ? '✅' : '❌'}</span>`
				button.innerHTML += `<span>${layerName}</span>`
			}
		})
	}

	onRemove () {
		this.container.parentNode?.removeChild(this.container)
	}
}

class ActivePolygonControl implements IControl {
	container: HTMLDivElement
	labelContainer: HTMLDivElement
	activePolygonLabel: string
	clearActivePolygon: () => void

	constructor (activePolygonLabel: string, clearActivePolygon: () => void) {
		this.activePolygonLabel = activePolygonLabel
		this.clearActivePolygon = clearActivePolygon
		this.container = document.createElement('div')
		this.container.className = 'pointer-events-auto mapboxgl-ctrl'
		this.labelContainer = document.createElement('div')
		this.labelContainer.className = 'flex items-center justify-between p-2 bg-white rounded shadow-md'

		// Initialize visibility based on the initial label
		this.setVisibility()
	}

	onAdd () {
		const text = document.createElement('p')
		text.textContent = `${this.activePolygonLabel}`
		text.className = 'pr-2 text-sm font-bold capitalize whitespace-nowrap'
		this.labelContainer.appendChild(text)

		const button = document.createElement('button')
		button.textContent = 'Reset'
		button.className = 'px-2 py-1 text-white rounded shadow-sm'
		button.style.backgroundColor = '#FF5733'
		button.style.transition = 'background-color 0.1s ease'
		button.onmouseenter = () => {
			button.style.backgroundColor = 'rgba(255, 87, 47, 0.8)'
		}
		button.onmouseleave = () => {
			button.style.backgroundColor = '#FF5733'
		}
		button.onclick = this.clearActivePolygon
		this.labelContainer.appendChild(button)
		this.container.appendChild(this.labelContainer)

		return this.container
	}

	updateLabel (activePolygonLabel: string) {
		this.activePolygonLabel = activePolygonLabel
		const text = this.labelContainer.children[0] as HTMLParagraphElement
		text.textContent = `${this.activePolygonLabel}`
		this.setVisibility()
	}

	setVisibility () {
		// Hide the container if the label is empty, show it otherwise
		this.container.style.display = this.activePolygonLabel ? 'flex' : 'none'
	}

	onRemove () {
		this.container.parentNode?.removeChild(this.container)
	}
}

class LegendControl implements mapboxgl.IControl {
	container: HTMLDivElement
	buttonContainer: HTMLDivElement
	contentContainer: HTMLDivElement
	contentInnerContainer: HTMLDivElement
	legends: Legend[]
	isExpanded: boolean
	map: mapboxgl.Map

	constructor (legends: Legend[], map: mapboxgl.Map) {
		this.legends = legends
		this.map = map
		this.isExpanded = false

		// Main container for the entire control
		this.container = document.createElement('div')
		this.container.className = 'mapboxgl-ctrl font-bold pointer-events-auto max-w-[12rem]'

		// Button container for the toggle button
		this.buttonContainer = document.createElement('div')
		this.buttonContainer.className = 'flex items-center justify-start'

		// Button to toggle visibility of the legend content
		const toggleButton = document.createElement('button')
		toggleButton.className = 'p-1 rounded border-2 border-black/30 shadow-sm cursor-pointer w-[48px] h-[34px] border-0 border-black/10'
		toggleButton.style.backgroundColor = 'white'
		toggleButton.style.transition = 'background-color 0.1s ease'
		toggleButton.onmouseenter = () => {
			toggleButton.style.backgroundColor = '#E6E6E6'
		}
		toggleButton.onmouseleave = () => {
			toggleButton.style.backgroundColor = 'white'
		}
		toggleButton.onclick = () => this.toggleVisibility()

		const img = document.createElement('img')
		img.src = MapLegendIcon
		img.alt = 'Toggle Legend'
		img.className = 'w-[40px] h-auto m-auto rounded text-mx-gray-500'

		toggleButton.appendChild(img)

		this.buttonContainer.appendChild(toggleButton)
		this.container.appendChild(this.buttonContainer)

		// Content container for the legend items
		this.contentContainer = document.createElement('div')
		this.contentContainer.className = 'mt-1 overflow-hidden transition-all duration-300 ease-in-out rounded shadow-sm bg-white/95'
		this.contentContainer.style.maxHeight = this.isExpanded ? '100%' : '0'

		this.contentInnerContainer = document.createElement('div')
		this.contentInnerContainer.className = 'border-2 rounded border-black/10'

		this.legends.forEach((legend) => {
			const section = document.createElement('div')
			section.className = 'grid items-start justify-center w-full grid-cols-1 px-4 pb-4 divide-y-2 first-of-type:pt-2'

			const title = document.createElement('div')
			title.className = 'text-base font-bold text-center capitalize'
			title.textContent = legend.title
			section.appendChild(title)

			const content = document.createElement('div')
			content.className = 'flex flex-col gap-1 pt-2'

			legend.items.forEach((item) => {
				const itemContainer = document.createElement('div')
				itemContainer.className = 'flex items-center space-x-2'

				if (item.type === 'marker') {
					const marker = document.createElement('div')
					marker.className = 'relative w-4 h-4 border rounded-full border-mx-gray-500'
					marker.style.backgroundColor = item.color
					if (item.iconUrl) {
						const img = document.createElement('img')
						img.src = item.iconUrl
						img.alt = 'Legend Item'
						img.className = 'w-2.5 h-2.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						marker.appendChild(img)
					}
					itemContainer.appendChild(marker)
				} else if (item.type === 'line') {
					const line = document.createElement('div')
					line.className = `w-24 border-4 ${item.style === 'dashed' ? 'border-dashed' : 'border-solid'} first-of-type:rounded last-of-type:rounded`
					line.style.borderColor = item.color
					itemContainer.appendChild(line)
				}

				const label = document.createElement('span')
				label.className = 'text-xs font-semibold capitalize whitespace-nowrap'
				label.className += item.type === 'line' ? 'ml-auto text-right w-24' : ''
				const itemName = item.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
				label.textContent = itemName
				itemContainer.appendChild(label)

				content.appendChild(itemContainer)
			})

			section.appendChild(content)
			this.contentInnerContainer.appendChild(section)
		})

		this.contentContainer.appendChild(this.contentInnerContainer)
		this.container.appendChild(this.contentContainer)
	}

	onAdd () {
		return this.container
	}

	toggleVisibility () {
		this.isExpanded = !this.isExpanded
		this.contentContainer.style.maxHeight = this.isExpanded ? `${this.contentContainer.scrollHeight}px` : '0'
	}

	onRemove () {
		this.container.parentNode?.removeChild(this.container)
	}
}

class LassoActionControl implements IControl {
	// define elements, data, and functions
	container: HTMLDivElement
	labelContainer: HTMLDivElement
	lassoPolygonIds: string[]
	clearLassoPolygon: () => void
	getExportData: () => void

	// initialize the control with styles, data, and functions
	constructor (
		lassoPolygonIds: string[],
		clearLassoPolygon: () => void,
		getExportData: () => void
	) {
		this.lassoPolygonIds = lassoPolygonIds
		this.clearLassoPolygon = clearLassoPolygon
		this.getExportData = getExportData

		this.container = document.createElement('div')
		this.container.className = 'pointer-events-auto mapboxgl-ctrl'
		this.labelContainer = document.createElement('div')
		this.labelContainer.className = 'flex items-center justify-between p-2 bg-white rounded shadow-md'

		// Initialize visibility based on the initial label
		this.setVisibility()
	}

	onAdd () {
		// Create a text element to display the selected IDs
		const text = document.createElement('p')
		text.textContent = `${this.lassoPolygonIds.length} selected`
		text.className = 'pr-2 text-sm font-bold capitalize whitespace-nowrap'
		this.labelContainer.appendChild(text)

		// Create the "Reset" button to clear the lasso polygon selection
		const resetButton = document.createElement('button')
		resetButton.textContent = 'Reset'
		resetButton.className = 'px-2 py-1 text-white rounded shadow-sm'
		resetButton.style.backgroundColor = '#FF5733'
		resetButton.style.transition = 'background-color 0.1s ease'
		resetButton.onmouseenter = () => {
			resetButton.style.backgroundColor = 'rgba(255, 87, 47, 0.8)'
		}
		resetButton.onmouseleave = () => {
			resetButton.style.backgroundColor = '#FF5733'
		}
		resetButton.onclick = this.clearLassoPolygon
		this.labelContainer.appendChild(resetButton)

		// Create the "Export" button to copy the GeoJSON to the clipboard
		const exportButton = document.createElement('button')
		exportButton.textContent = 'Export'
		exportButton.className = 'px-2 py-1 ml-2 text-white rounded shadow-sm'
		exportButton.style.backgroundColor = '#000000'
		exportButton.style.transition = 'background-color 0.1s ease'
		exportButton.onmouseenter = () => {
			exportButton.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'
		}
		exportButton.onmouseleave = () => {
			exportButton.style.backgroundColor = '#000000'
		}
		exportButton.onclick = () => {
			this.getExportData()
		}
		this.labelContainer.appendChild(exportButton)

		// Append the label container to the control container
		this.container.appendChild(this.labelContainer)

		// Return the control container
		return this.container
	}

	updateLabel (lassoPolygonIds: string[]) {
		this.lassoPolygonIds = lassoPolygonIds
		const text = this.labelContainer.children[0] as HTMLParagraphElement
		text.textContent = `${lassoPolygonIds.length} selected`
		this.setVisibility()
	}

	setVisibility () {
		// Hide the container if there are no selected IDs
		this.container.style.display = this.lassoPolygonIds.length ? 'flex' : 'none'
	}

	onRemove () {
		this.container.parentNode?.removeChild(this.container)
	}
}

export { StyleSwitcherControl, LayerVisibilityControl, ActivePolygonControl, LegendControl, LassoActionControl }
