import type { MappedZone, MappedZonePolygon } from '@/components/zones/types'
import type { LngLatLike, CircleLayerSpecification, LineLayerSpecification, FillLayerSpecification } from 'mapbox-gl'
import type { Feature, FeatureCollection, Point, Polygon, Position, Geometry, GeoJsonProperties } from 'geojson'
import {
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	ACCOUNT_STATUSES,
	ACCOUNT_STATUS_COLORS,
	FAILED_INSTALL
} from '@/constants/accounts/statuses'

import type { AccountStatus } from '@/types/account-statuses'

interface AccountsMapMarker extends Point {
	id: string
	accountStatus: AccountStatus
	zoneId: string
	insideInstallStatus: string
	surveyStatus: string
	hasEquipment: string
}

const PLANNED = 'planned'
const COMPLETED = 'completed'
const VERIFIED = 'verified'

const MAINLINE_STATUSES = [ PLANNED, COMPLETED, VERIFIED ]

const MAINLINE_STATUS_COLORS = {
	PLANNED: '#FE9900',
	COMPLETED: '#3A8B1B',
	VERIFIED: '#eefc08'
}

const ZONE_TYPES: { [key: string]: string } = {
	EXTENDED: 'extended',
	IN_SERVICE: 'in_service'
}

const DEFAULT_CENTER: LngLatLike = [ -104.7595, 39.5183 ]
const DEFAULT_ZOOM = 11

const MARKERS_SOURCE_ID = 'service-locations-source'
const MARKERS_LAYER_ID = 'service-locations-layer'

const ZONES_SOURCE_ID = 'zones-source'
const ZONES_LAYER_ID = 'zones-layer'
const ZONES_LINE_LAYER_ID = 'zones-line-layer'

const MAINLINE_SOURCE_ID = 'mainlines-source'
const MAINLINE_MARKERS_LAYER_ID = 'mainline-handholes-layer'
const MAINLINE_LINE_STRINGS_LAYER_ID = 'mainline-line-layer'

const ACTIVE_ZONE_MARKER_FILTER_ID = 'active-zone-marker-filter'
const ACTIVE_STATUS_MARKER_FILTER_ID = 'active-status-marker-filter'

const HAS_EQUIPMENT_MARKER_FILTER_ID = 'has-equipment-marker-filter'

const ACTIVE_ZONE_LINE_LAYER_FILTER_ID = 'active-zone-line-layer-filter'
const ACTIVE_ZONE_FILL_LAYER_FILTER_ID = 'active-zone-fill-layer-filter'

const TABLE_DATA_MARKER_FILTER_ID = 'table-data-marker-filter'

export const useAccountsMapbox = () => {
	const mapZonesToGeoJson = (zones: MappedZone[]): FeatureCollection<Geometry, GeoJsonProperties> => {
		const zonesGeoJsonData: FeatureCollection<Polygon, GeoJsonProperties> = {
			type: 'FeatureCollection',
			features: []
		}
		const polygons: MappedZonePolygon[] = zones?.map((zone) => {
			const coordinates: Position[][] = JSON.parse(zone.points || '')?.features?.[0]?.geometry?.coordinates?.[0] || [ [ 0, 0, 0 ] ]
			return {
				id: zone.id,
				zoneType: zone.type || '',
				zoneName: zone.name,
				coordinates,
				color: zone.color || '#000000'
			}
		})

		// REVIEW: For some reason, the Coordinates need to be 3 levels deep (Position[][][])
		// instead of the expected 2 levels deep (Position[][]) that Polygon expects
		// this still works with a type assertion
		const features: Feature<Polygon, GeoJsonProperties>[] = polygons?.map(polygon => ({
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [ polygon.coordinates ] as unknown as Position[][]
			},
			properties: {
				id: polygon.id,
				zoneType: polygon.zoneType,
				zoneName: polygon.zoneName,
				color: polygon.color
			}
		}))

		zonesGeoJsonData.features = features || []

		return zonesGeoJsonData
	}

	const mapMarkersToGeoJson = (markers: AccountsMapMarker[] | undefined): FeatureCollection<Point, GeoJsonProperties> | undefined => {
		if (!markers) { return }

		const markerData: FeatureCollection<Point, GeoJsonProperties> = {
			type: 'FeatureCollection',
			features: []
		}

		const features: Feature<Point, GeoJsonProperties>[] = markers
			?.map((marker: AccountsMapMarker): Feature<Point, GeoJsonProperties> => ({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: marker.coordinates
				},
				properties: {
					id: marker.id,
					accountStatus: marker.accountStatus,
					insideInstallStatus: marker.insideInstallStatus,
					surveyStatus: marker.surveyStatus,
					zoneId: marker.zoneId,
					hasEquipment: marker.hasEquipment
				}
			}))

		markerData.features = features || []
		return markerData
	}

	const getAccountStatusBgColor = (status: AccountStatus): string => {
		return ACCOUNT_STATUS_COLORS[status] ?? '#000000'
	}

	const getAccountStatusTextColor = (status: AccountStatus): string => {
		return getAccountStatusBgColor(status) === '#000000' ? '#FFFFFF' : '#000000'
	}

	const getEquipmentStatusColor = (status: string) => {
		switch (status) {
		case 'active':
			return ACCOUNT_STATUS_COLORS[ACTIVE]
		case 'disconnected':
			return '#C70039' // mx-red
		default:
			return ''
		}
	}

	const getMainlineStatusColor = (status: string) => {
		switch (status) {
		case PLANNED:
			return MAINLINE_STATUS_COLORS.PLANNED
		case COMPLETED:
			return MAINLINE_STATUS_COLORS.COMPLETED
		case VERIFIED:
			return MAINLINE_STATUS_COLORS.VERIFIED
		default:
			return '#000000'
		}
	}

	const ZONES_FILL_LAYER: FillLayerSpecification = {
		id: ZONES_LAYER_ID,
		type: 'fill',
		source: ZONES_SOURCE_ID,
		paint: {
			'fill-color': [ 'get', 'color' ],
			'fill-opacity': [
				'match',
				[ 'get', 'zoneType' ],
				ZONE_TYPES.EXTENDED, 0.25,
				ZONE_TYPES.IN_SERVICE, 0.25,
				/* Default to invisible */ 0
			]
		},
		layout: {
			visibility: 'none'
		}
	}

	const ZONES_LINE_LAYER: LineLayerSpecification = {
		id: ZONES_LINE_LAYER_ID,
		type: 'line',
		source: ZONES_SOURCE_ID,
		paint: {
			'line-color': [ 'get', 'color' ],
			'line-width': 2,
			'line-opacity': [
				'match',
				[ 'get', 'zoneType' ],
				ZONE_TYPES.EXTENDED, 1.0,
				ZONE_TYPES.IN_SERVICE, 0.5,
				/* Default to invisible */ 0
			],
			'line-dasharray': [
				'match',
				[ 'get', 'zoneType' ],
				ZONE_TYPES.EXTENDED, [ 2, 4 ],
				ZONE_TYPES.IN_SERVICE, [ 1, 0 ],
				[ 1, 0 ] // Default to solid line
			]
		},
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
			visibility: 'none'
		}
	}

	const MARKERS_LAYER: CircleLayerSpecification = {
		source: MARKERS_SOURCE_ID,
		id: MARKERS_LAYER_ID,
		type: 'circle',
		paint: {
			'circle-color': [
				'match',
				[ 'get', 'accountStatus' ],
				IGNORE, ACCOUNT_STATUS_COLORS[IGNORE],
				ACTIVE, ACCOUNT_STATUS_COLORS[ACTIVE],
				LEAD, ACCOUNT_STATUS_COLORS[LEAD],
				PROSPECT, ACCOUNT_STATUS_COLORS[PROSPECT],
				OPPORTUNITY, ACCOUNT_STATUS_COLORS[OPPORTUNITY],
				PENDING_INSTALL, ACCOUNT_STATUS_COLORS[PENDING_INSTALL],
				PENDING_ACCEPTANCE, ACCOUNT_STATUS_COLORS[PENDING_ACCEPTANCE],
				INACTIVE, ACCOUNT_STATUS_COLORS[INACTIVE],
				FAILED_INSTALL, ACCOUNT_STATUS_COLORS[FAILED_INSTALL],
				/* default */ '#000000'
			],
			'circle-radius': 5,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#8596ad'
		},
		filter: [ '!', [ 'has', 'point_count' ] ],
		layout: {
			visibility: 'visible'
		}
	}

	const MAINLINE_MARKERS_LAYER: CircleLayerSpecification = {
		source: MAINLINE_SOURCE_ID,
		id: MAINLINE_MARKERS_LAYER_ID,
		type: 'circle',
		paint: {
			'circle-color': [
				'match',
				[ 'get', 'status' ],
				PLANNED, MAINLINE_STATUS_COLORS.PLANNED,
				COMPLETED, MAINLINE_STATUS_COLORS.COMPLETED,
				VERIFIED, MAINLINE_STATUS_COLORS.VERIFIED,
				/* default */ '#000000'
			],
			'circle-radius': 5,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#8596ad'
		},
		filter: [
			'all',
			[ '==', 'category_name', 'Handhole' ],
			[ '==', '$type', 'Point' ]
		],
		layout: {
			visibility: 'none'
		}
	}

	const MAINLINE_LINE_STRINGS_LAYER: LineLayerSpecification = {
		source: MAINLINE_SOURCE_ID,
		id: MAINLINE_LINE_STRINGS_LAYER_ID,
		type: 'line',
		paint: {
			'line-color': [
				'match',
				[ 'get', 'status' ],
				PLANNED, MAINLINE_STATUS_COLORS.PLANNED,
				COMPLETED, MAINLINE_STATUS_COLORS.COMPLETED,
				VERIFIED, MAINLINE_STATUS_COLORS.VERIFIED,
				/* default */ '#000000'
			],
			'line-width': 5,
			'line-dasharray': [
				'match',
				[ 'get', 'status' ],
				// Dash pattern for planned line
				PLANNED, [ 2, 4 ],
				// Solid line for completed line
				COMPLETED, [ 1, 0 ],
				VERIFIED, [ 1, 0 ], // Solid line for verified line
				[ 1, 0 ] // Default to solid line
			]
		},
		filter: [
			'all',
			[ '==', '$type', 'LineString' ] // Filter for LineString geometries
		],
		layout: {
			'line-cap': 'round',
			visibility: 'none'
		}
	}

	const HAS_EQUIPMENT_MARKER_LAYER_FILTER = {
		layerId: MARKERS_LAYER_ID,
		filterId: HAS_EQUIPMENT_MARKER_FILTER_ID,
		expression: [ '==', [ 'get', 'hasEquipment' ], 'true' ],
		visibleLayerIds: [ MARKERS_LAYER_ID, ZONES_LAYER_ID, ZONES_LINE_LAYER_ID ]
	}

	const replaceUnderscoresWithSpaces = (str: string): string => {
		return str.replace(/_/g, ' ')
	}

	/* TODO i18n */
	const replaceTrueFalseWithYesNo = (value: any): string => {
		if (value === true) { return 'Yes' }
		if (value === false) { return 'No' }
		return value.toString()
	}

	const setVitruviPopup = (properties: Record<string, any>): string => {
		let content = '<div class="dark:text-mx-gray-300 h-36 overflow-y-auto">'
		for (const [ key, value ] of Object.entries(properties)) {
			content += `<div class=""><strong class="capitalize">${replaceUnderscoresWithSpaces(key)}:</strong> ${replaceTrueFalseWithYesNo(String(value))}</div>`
		}
		content += '</div>'
		return content
	}

	return {
		ACCOUNT_STATUSES,
		ZONE_TYPES,
		DEFAULT_CENTER,
		DEFAULT_ZOOM,
		mapZonesToGeoJson,
		mapMarkersToGeoJson,
		getAccountStatusBgColor,
		getAccountStatusTextColor,
		getEquipmentStatusColor,
		getMainlineStatusColor,
		MARKERS_SOURCE_ID,
		MARKERS_LAYER,
		MARKERS_LAYER_ID,
		ZONES_SOURCE_ID,
		ZONES_FILL_LAYER,
		ZONES_LINE_LAYER,
		ZONES_LAYER_ID,
		ZONES_LINE_LAYER_ID,
		MAINLINE_SOURCE_ID,
		MAINLINE_MARKERS_LAYER,
		MAINLINE_MARKERS_LAYER_ID,
		MAINLINE_LINE_STRINGS_LAYER,
		MAINLINE_LINE_STRINGS_LAYER_ID,
		MAINLINE_STATUS_COLORS,
		MAINLINE_STATUSES,
		ACTIVE_ZONE_FILL_LAYER_FILTER_ID,
		ACTIVE_ZONE_LINE_LAYER_FILTER_ID,
		ACTIVE_ZONE_MARKER_FILTER_ID,
		ACTIVE_STATUS_MARKER_FILTER_ID,
		HAS_EQUIPMENT_MARKER_FILTER_ID,
		HAS_EQUIPMENT_MARKER_LAYER_FILTER,
		TABLE_DATA_MARKER_FILTER_ID,
		PLANNED,
		COMPLETED,
		VERIFIED,
		setVitruviPopup
	}
}

export type { AccountsMapMarker }
export { ZONES_LAYER_ID, ZONES_LINE_LAYER_ID }
